import { useSelector } from 'react-redux';

import ResponsibleBettingTimeLimitModal from 'components/ResponsibleBetting/components/ResponsibleBettingTimeLimitModal';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getShowTimeLimitReminder } from 'redux/modules/responsibleBetting/selectors';

const ResponsibleBettingModals = () => {
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const showTimeLimitReminder = useSelector(getShowTimeLimitReminder);

  if (isLoggedIn && showTimeLimitReminder) {
    return <ResponsibleBettingTimeLimitModal />;
  }

  return null;
};

export default ResponsibleBettingModals;
