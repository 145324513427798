import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SAFE_TIME_OUT_PERIOD_IN_MINUTES } from 'constants/app';
import { SELF_EXCLUSION_REMINDER_MINUTES } from 'constants/responsibleBetting';
import { getIsTimeLimitNotificationsEnabled } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchResponsibleBettingSettings, setShowTimeLimitReminder } from 'redux/modules/responsibleBetting';
import {
  getIsSelfExclusionEnabled,
  getResponsibleBettingSettings,
  getShowTimeLimitReminder
} from 'redux/modules/responsibleBetting/selectors';

export const useTimeLimit = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isSelfExclusionEnabled = useSelector(getIsSelfExclusionEnabled);
  const rbSettings = useSelector(getResponsibleBettingSettings);
  const showTimeLimitReminder = useSelector(getShowTimeLimitReminder);
  const isTimeLimitNotificationsEnabled = useSelector(getIsTimeLimitNotificationsEnabled);

  const { timeLimitAvailable = null, timeLimitEndDate = null } = rbSettings?.rgTools || {};
  const safeTimeOutDuration =
    !!timeLimitAvailable && timeLimitAvailable > SAFE_TIME_OUT_PERIOD_IN_MINUTES
      ? SAFE_TIME_OUT_PERIOD_IN_MINUTES
      : timeLimitAvailable || 0;

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const showReminder = () => {
    dispatch(setShowTimeLimitReminder(true));
    clearTimer();
  };

  const refetchResponsibleBettingSettings = () => {
    dispatch(setShowTimeLimitReminder(false));
    dispatch(fetchResponsibleBettingSettings());
  };

  useEffect(() => {
    if (!timeoutRef.current && timeLimitAvailable !== null && !showTimeLimitReminder) {
      if (
        (timeLimitAvailable >= SELF_EXCLUSION_REMINDER_MINUTES && !isTimeLimitNotificationsEnabled) ||
        (timeLimitAvailable < SELF_EXCLUSION_REMINDER_MINUTES && timeLimitAvailable > 0)
      ) {
        timeoutRef.current = setTimeout(refetchResponsibleBettingSettings, safeTimeOutDuration * 60 * 1000);
      }
    }
  }, [timeLimitAvailable]);

  useEffect(() => {
    if (!isLoggedIn && isSelfExclusionEnabled) {
      clearTimer();
      return;
    }

    clearTimer();

    if (!timeLimitAvailable) {
      return;
    }

    if (timeLimitAvailable !== null && timeLimitAvailable <= 0) {
      refetchResponsibleBettingSettings();
      return;
    }

    if (timeLimitAvailable !== null && !showTimeLimitReminder) {
      if (timeLimitAvailable >= SELF_EXCLUSION_REMINDER_MINUTES && isTimeLimitNotificationsEnabled) {
        timeoutRef.current = setTimeout(
          showReminder,
          (safeTimeOutDuration - SELF_EXCLUSION_REMINDER_MINUTES) * 60 * 1000
        );
      } else if (
        (timeLimitAvailable >= SELF_EXCLUSION_REMINDER_MINUTES && !isTimeLimitNotificationsEnabled) ||
        (timeLimitAvailable < SELF_EXCLUSION_REMINDER_MINUTES && timeLimitAvailable > 0)
      ) {
        timeoutRef.current = setTimeout(refetchResponsibleBettingSettings, safeTimeOutDuration * 60 * 1000);
      }
    }

    return clearTimer;
  }, [timeLimitEndDate, showTimeLimitReminder, isLoggedIn]);

  useEffect(() => {
    return clearTimer;
  }, []);
};
