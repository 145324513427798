import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Modal from 'components/Modal';
import ResponsibleBettingBtn from 'components/ResponsibleBetting/components/ResponsibleBettingBtn';
import { GAMES_BASE_URL, HOME_BASE_URL, RESPONSIBLE_BETTING_BASE_URL } from 'constants/locations';
import {
  RESPONSIBLE_BETTING_SETTINGS_REQUEST_INTERVAL,
  SELF_EXCLUSION_REMINDER_MINUTES
} from 'constants/responsibleBetting';
import useDevice from 'hooks/useDevice';
import { getIsExchangeSportsEnabled } from 'redux/modules/appConfigs/selectors';
import { logoutUser } from 'redux/modules/auth';
import { fetchResponsibleBettingSettings, setShowTimeLimitReminder } from 'redux/modules/responsibleBetting';
import { getResponsibleBettingSettings, getShowTimeLimitReminder } from 'redux/modules/responsibleBetting/selectors';
import { ResponsibleBettingButtonTypes } from 'types/responsibleBetting';

import styles from './styles.module.scss';

const ResponsibleBettingTimeLimitModal = () => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isVisible, setVisible] = useState(true);

  const isResponsibleBettingPage = location.pathname.includes(RESPONSIBLE_BETTING_BASE_URL);

  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const showTimeLimitReminder = useSelector(getShowTimeLimitReminder);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const rbSettings = useSelector(getResponsibleBettingSettings);
  const { timeLimitAvailable = null } = rbSettings?.rgTools || {};
  const isTimeAvailableLess10 =
    !!timeLimitAvailable && timeLimitAvailable <= SELF_EXCLUSION_REMINDER_MINUTES && timeLimitAvailable >= 0;
  const timeLeft = isTimeAvailableLess10 ? timeLimitAvailable : SELF_EXCLUSION_REMINDER_MINUTES;
  const isClosing = timeLeft === 0;

  const upadteTime = () => {
    if (!isResponsibleBettingPage) {
      dispatch(fetchResponsibleBettingSettings());
    }
  };

  useEffect(() => {
    if (showTimeLimitReminder && isClosing) {
      dispatch(setShowTimeLimitReminder(false));
      setVisible(true);
    }
  }, [isClosing]);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    if (showTimeLimitReminder) {
      dispatch(fetchResponsibleBettingSettings());
      intervalRef.current = setInterval(upadteTime, RESPONSIBLE_BETTING_SETTINGS_REQUEST_INTERVAL);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [showTimeLimitReminder, isResponsibleBettingPage]);

  return (
    <Modal
      open={showTimeLimitReminder && isTimeAvailableLess10 && isVisible}
      title={t('responsibleBetting.titles.timeLimitWarning')}
      customClassNames={{
        dialog: isMobile ? styles.rbSessionModal__dialog__mobile : styles.rbSessionModal__dialog,
        content: styles.rbSessionModal__content,
        header: styles.rbSessionModal__header,
        title: styles.rbSessionModal__title,
        body: styles.rbSessionModal__body
      }}
      onClose={() => setVisible(false)}
      clickableBackground
    >
      <div>
        <div className={styles.rbSessionModal__alert}>
          <i className={classNames(styles.rbSessionModal__icon, 'ion ion-android-time')} />
          <div className={styles.rbSessionModal__messageContainer}>
            <span className={styles.rbSessionModal__message}>
              {t('responsibleBetting.messages.restrictedBettingSoon')}
            </span>
            <span className={styles.rbSessionModal__time}>
              {t('responsibleBetting.messages.timeLimitWarning', { N: timeLeft })}
            </span>
          </div>
        </div>
        <p className={styles.rbSessionModal__confirmation}>{t('responsibleBetting.messages.takeBreak')}</p>
        <div className={styles.rbSessionModal__actionsContainer}>
          <ResponsibleBettingBtn
            label={t('responsibleBetting.labels.noContinue')}
            onClick={() => setVisible(false)}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Secondary}
            customClass={styles.rbSessionModal__action}
          />

          <ResponsibleBettingBtn
            label={t('responsibleBetting.labels.yesLogOut')}
            onClick={() => {
              setVisible(false);
              dispatch(setShowTimeLimitReminder(false));
              dispatch(logoutUser(isExchangeSportsEnabled ? HOME_BASE_URL : GAMES_BASE_URL));
            }}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Primary}
            customClass={styles.rbSessionModal__action}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ResponsibleBettingTimeLimitModal;
